$primary-color: #ff2727;
$primary-hover: #e22727;

$primary-dark: #212121;

$primary-bg: #fcfcfc;

$white: #fff;

// mixins

// flex center
@mixin flex-c {
    display: flex;
    align-items: center;
}

// flex center center
@mixin flex-cc {
    display: flex;
    align-items: center;
    justify-content: center;
}

// flex center between
@mixin flex-cb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

// flex column
@mixin flex-col {
    display: flex;
    flex-direction: column;
}

@import "variables";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#root,
html,
body {
    height: 100%;
}

html {
    font-size: 100%;
}

h6 {
    margin-bottom: 0;
}

// buttons
.btn:focus {
    box-shadow: none;
}

.btn:hover {
    color: currentColor;
}

.btn.btn-regular-primary {
    background: $primary-color;
    color: $white;
    text-transform: uppercase;
    border-radius: 10rem;
    font-size: 0.9rem;
    padding: 0 1.75rem;
    margin-top: 1.5rem;
    // transition: transform 150s ease-in-out;
    &:hover {
        background: $primary-hover;
    }
    &:active {
        transform: scale(0.98);
    }
}

.btn.btn-crosscall {
    background-color: $primary-color;
    color: $white;
    &:hover {
        background-color: $primary-hover;
        color: $white;
    }
}

.btn-text-light {
    color: $white;
    &:hover {
        color: $primary-color;
    }
}

.btn-label-crosscall {
    background: #fde;
    color: $primary-color;
}

a.btn-label-crosscall:hover {
    background: $primary-color;
    color: $white;
}

span.btn-label-crosscall:hover {
    background: #fde;
    color: $primary-color;
}

.btn-menu-crosscall,
.btn-menu-crosscall i {
    color: #74788d;
}

.btn-menu-crosscall:hover {
    color: $primary-color;
    background: #f2f3f7;
}

.btn-menu-crosscall:hover i {
    color: $primary-color;
}

.btn-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.invalid-text {
    width: 100%;
    font-size: 80%;
    color: #dc3545;
}

.text-primary {
    color: $primary-color !important;
}

a.text-primary:hover {
    color: $primary-hover !important;
    text-decoration: underline;
}

// utilities
.bg-crosscall {
    background-color: $primary-color;
}

.bg-crosscall-dark {
    background-color: $primary-dark;
}

.btns-adjacent {
    @include flex-cb;
}

.btns-adjacent > .btn {
    width: 48%;
}

.pointer {
    cursor: pointer;
}

// custom checkmark
.check-container {
    display: block;
    position: relative;
    width: 21px;
    height: 21px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
}

.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #eee;
}

.check-container:hover input ~ .checkmark {
    background-color: #ccc;
}

.check-container input:checked ~ .checkmark {
    background-color: $primary-color;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-container input:checked ~ .checkmark:after {
    display: block;
}

.check-container .checkmark:after {
    left: 6.5px;
    top: 1px;
    width: 7px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

// media
@media (min-width: 768px) {
    html {
        font-size: 1.15rem;
    }

    .main-nav-button--show-list {
        position: absolute !important;
        right: 2rem;
    }

    .main-nav-buttons {
        max-width: 350px !important;
        padding: 1rem !important;
    }
}

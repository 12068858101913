@import "../../variables";
$button-size: 55px;
$button-opacity: 0.8;

// utilities
.bring-to-front {
    z-index: 2;
}

a.main-nav-button:hover {
    color: currentColor;
    text-decoration: none;
}

a.main-nav-button--show-list:hover {
    text-decoration: none;
    color: $white;
    background: #000;
}

// sections
.main-home-container {
    min-height: 100%;
    background: $primary-bg;
}

// menu
// .menu {
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: -12%;
//     z-index: 3;
//     width: 12%;
//     background: $primary-dark;
//     transition: left 350ms ease-in-out;
// }

// .show-menu .menu-expanded {
//     left: 0;
// }

// .menu-top-section {
//     @include flex-cc;
//     flex-direction: column;
//     // gap: 0.7rem;
//     // grid-gap: 0.7rem;
//     height: 25vh;
//     background: $white;
//     color: #000;
//     & > * + * {
//         margin-top: 0.7rem;
//     } // replaces gap
// }

// .fillin-image {
//     width: 40px;
//     height: 40px;
//     background: #ccc;
//     border-radius: 50%;
// }

// .menu-list {
//     list-style: none;
//     li {
//         padding: 1rem;
//         cursor: pointer;
//         color: $white;
//         &:hover {
//             background: #444;
//         }
//     }
// }

.language-selector {
    position: absolute;
    top: 1rem;
    right: 1rem;
    &.language-selector--menu {
        position: relative;
        top: auto;
        right: auto;
        margin-left: 1rem;
    }
}

// loader
.loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include flex-cc;
    flex-direction: column;
    // gap: 2rem;
    // grid-gap: 2rem;
    z-index: 100;
    background: rgba($color: #fff, $alpha: 0.8);
    padding: 1rem 2rem;
    > * + * {
        margin-top: 2rem;
    } // replaces gap
    > h4 {
        font-size: 2rem;
    }
    > span {
        width: 8rem;
        height: 8rem;
        border-width: 0.75rem;
        color: $primary-color;
    }
}

// header
.header-row {
    position: sticky;
    top: 0;
    @include flex-cb;
    width: 100%;
    background: $white;
    z-index: 3;
    padding: 1rem;
    box-shadow: 0px -1px 6px 0px rgba($color: #000000, $alpha: 0.6);
    & .header-icons {
        display: flex;
        // gap: 1.2rem;
        // grid-gap: 1.2rem;
        & .rotate {
            transform: rotate(90deg);
        }
        & > * + * {
            margin-left: 1.2rem;
        } // replaces gap
    }
}

.map-list-nav {
    display: flex;
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 2px 0px rgba($color: #000000, $alpha: 0.6);
    background: $white;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: $primary-color;
        width: 50%;
        height: 5px;
        transition: left 350ms ease-in-out;
    }
    &.after--right::after {
        left: 50%;
    }
}

.map-list-nav--item {
    flex: 1;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
}

// map
.map-container {
    width: 100%;
    height: 100%;
    // transition: margin-left 350ms ease-in-out, width 350ms ease-in-out;
}

.map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

// .mapboxgl-control-container {
//     position: absolute;
//     bottom: 0;
//     font-size: 0.8rem;
//     margin: 0 0 5px 5px;
// }

// popup is just an active icon
// .mapboxgl-popup-content.user-popup {
//     padding: 0 !important;
//     width: 50px;
//     height: 50px;
//     border-radius: 50% !important;
//     background-color: transparent !important;
//     border: 4px solid rgba($color: #ff0000, $alpha: 0.7);
//     & .mapboxgl-popup-close-button {
//         display: none;
//     }
// }

.mapboxgl-popup-content {
    // min-width: 150px;
    // width: 15vw;
    // max-width: 250px;
    width: clamp(150px, 50vw, 250px);
    & .mapboxgl-popup-close-button {
        font-size: 35px;
        padding: 10px;
    }
}

.mapboxgl-popup.user-popup .mapboxgl-popup-tip {
    display: none;
}

.popupInfo-menu {
    position: absolute;
    top: 0;
    left: -20%;
    bottom: 0;
    z-index: 3;
    width: 20%;
    background-color: white;
    transition: left 350ms ease-in-out;
    &.popupInfo-menu--open {
        left: 0;
    }
}

.marker {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 1.5rem;
    // width: 40px;
    // height: 40px;
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
}

// .user-popup-text {
//     position: absolute;
//     left: 130%;
//     top: 50%;
//     transform: translateY(-50%);
//     font-size: 14px;
//     font-weight: 600;
// }

// nav menu
.main-nav-buttons-wrapper {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 3;
    background: $white;
    box-shadow: 0px 1px 6px 0px rgba($color: #000000, $alpha: 0.6);
}

.main-nav-buttons {
    @include flex-cb;
    max-width: 450px;
    margin: 0 auto;
    // gap: 1.4rem;
    // grid-gap: 1.4rem;
    padding: 0 1rem;
    // padding: 0.6rem 1.6rem;
    font-size: 0.9rem;
    // & > * + * {
    //     margin-left: 1.4rem;
    // } // replaces gap
}

.main-nav-button {
    @include flex-cc;
    flex-direction: column;
    color: #444;
    cursor: pointer;
    > svg {
        font-size: 1.5rem;
    }
    > img {
        width: 1.5rem;
    }
    &.main-nav-button--settings-active {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            top: calc(100% + 1rem - 3px);
            left: 0;
            width: 100%;
            height: 4px;
            background: $primary-color;
        }
    }
}

.main-nav-button--show-list {
    @extend .main-nav-button;
    color: $white;
    background: $primary-dark;
    // if using red bg with shadow
    // background: $primary-color;
    // box-shadow: 0px 4px 6px 2px rgba($color: #000000, $alpha: 0.75);
    padding: 1.6rem;
    border-radius: 50%;
    position: relative;
    bottom: 2.25rem;
    margin-left: -0.75rem;
    cursor: pointer;
}

.main-nav-button--notifications {
    position: relative;
    > span {
        position: absolute;
        top: -6px;
        right: -6px;

        @include flex-cc;
        background: $primary-color;
        font-size: 0.8rem;
        color: white;
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        > span {
            font-size: 0.5rem;
        }
    }
}

// map list items
.wrapper--menu-padding {
    padding-bottom: 10vh;
}

.map-list-column {
    @include flex-col;
    // gap: 0.75rem;
    // grid-gap: 0.75rem;
    padding: 1rem;
    & > * + * {
        margin-top: 0.75rem;
    } // replaces gap
}

.map-list-item--outer {
    display: block;
    background: $white;
    padding: 0.75rem;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba($color: #000000, $alpha: 0.2);
    &.is-new {
        background: rgba($color: #000000, $alpha: 0.1);
    }
    &:hover {
        text-decoration: none;
    }
    &:last-child {
        margin-bottom: 1rem;
    }
}

.map-list-select-order {
    width: 100%;
    border-radius: 5px;
    border-color: rgba($color: #000000, $alpha: 0.25);
}

// .map-list-item--inner {
// }

.map-list-item--row {
    display: flex;
}

.map-list-item--title-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba($color: #000000, $alpha: 0.25);
    }
}

.map-list--underline {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba($color: #000000, $alpha: 0.25);
    }
}

.map-list--underline {
    position: relative;
}

.map-list-item--title + .map-list-item--inner {
    padding-top: 0.5rem;
}

.map-list-item--title {
    color: $primary-dark;
    // font-weight: 400;
    font-size: 0.9rem;
    flex: 1;
}

.offer-page-item--title {
    @extend .map-list-item--title;
    @extend .map-list--underline;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    &.no-margin-bottom {
        margin-bottom: 0;
    }
}

.map-list-item--icon {
    margin-right: 0.5rem;
}

.offer-page--icon {
    margin: 0 0.75rem 0 0.25rem;
    > img {
        width: 1.25rem;
    }
}

.category-icon {
    @include flex-cc;
    padding: 5px 10px;
    width: 45px;
    height: 45px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    > img {
        width: 1.25rem;
        max-height: 30px;
    }
}

.hide-icon {
    opacity: 0;
    height: 0;
}

.map-list-item--details {
    @include flex-col;
    flex: 1;
}

.map-list-item--offset-left {
    margin-left: 3.2rem;
}

.map-list-item--numbers {
    display: flex;
    padding: 0.5rem 0;
    > * {
        flex: 1;
        @include flex-c;
        // gap: 0.5rem;
        // grid-gap: 0.5rem;
        color: rgba($color: #000000, $alpha: 0.7);
        > img {
            margin-right: 0.5rem; // replaces gap
            width: 1.25rem;
        }
    }
    > .map-list-item--stars {
        // gap: 0;
        // grid-gap: 0;
        color: gold;
        & > * + * {
            margin-left: 0rem;
        } // replaces gap
    }
}

// offer page
.offer-page--heading {
    @include flex-c;
    padding: 0.75rem 1rem;
    background: $white;
    box-shadow: 0px 0px 6px 0px rgba($color: #000000, $alpha: 0.2);
}

.offer-page-item-inner {
    @include flex-col;
}

.offer-page-item-details--row {
    align-items: flex-start;
    padding: 0.75rem;
}

.offer-page-item--detail {
    @include flex-col;
    // gap: 0.5rem;
    // grid-gap: 0.5rem;
    text-align: center;
    & > * + * {
        margin-top: 0.5rem;
    } // replaces gap
}

.offer-page-item--details {
    display: flex;
    align-items: flex-start;
}

.offer-page-item-detail--icon {
    background: $primary-dark;
    padding: 0.75rem;
    border-radius: 10px;
    min-width: 48px;
    min-height: 48px;
    margin: auto;
    @include flex-cc;
    > svg,
    > img {
        color: $white;
        font-size: 1.375rem;
        height: 1.375rem;
    }
    // TODO: can remove svg later after all icons are images
}

.offer-page-contact--number img {
    height: 20px;
}

.offer-page-text--regular {
    margin-bottom: 0.2rem;
}

.offer-page-contact--number {
    @include flex-c;
    // gap: 1rem;
    // grid-gap: 1rem;
    margin-left: 0.25rem;
    & > * + * {
        margin-left: 0.75rem;
    } // replaces gap
}

.offer-page-contact--make-contact {
    @include flex-c;
    justify-content: space-around;
    margin: 1.25rem 0;
    & > svg {
        font-size: 1.6rem;
        color: $white;
        margin: 1rem;
    }
}

.offer-page-item-detail--icon-round-shadow {
    border-radius: 50%;
    padding: 1.25rem;
    width: auto;
    box-shadow: 0px 2px 6px 0px rgba($color: #000000, $alpha: 0.6);
    > img {
        height: 1.75rem;
    }
}

.offer-evaluation--stars:focus {
    outline: none;
}

// stats page
.stats-page-stat-row {
    @include flex-cb;
    margin-bottom: 0.25rem;
}

.stats-page-stat {
    position: relative;
    padding-left: 1rem;
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        border-radius: 3px;
        background-color: black;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
    }
}

// setting page
.settings-page--button {
    @include flex-c;
    > * {
        color: $primary-dark;
    }
    > img {
        width: 1.25rem;
    }
}

// toggle switch
.switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 23px;
    margin-bottom: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 22px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    top: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: $primary-color;
}

input:focus + .slider {
    box-shadow: 0 0 1px $primary-color;
}

input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

// end toggle sqitch

.notification-range-slider {
    flex: 1;
    -webkit-appearance: none;
    appearance: none;
    height: 3px;
    border-radius: 5px;
    background: #444;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 0.5rem 0;
    transition: background 250ms ease-in;

    &:hover {
        opacity: 1;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background: $primary-color;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background: $primary-color;
        border-radius: 50%;
        cursor: pointer;
    }
}

.password-change--group {
    margin-bottom: 1rem;
    > label {
        display: block;
        margin-bottom: 2px;
    }
    > input {
        padding: 0 0.25rem;
        width: 70%;
        height: initial;
        border: 1px solid #aaa;
    }
}

//  notification page
.notification--title {
    padding: 0 0 0.25rem;
    color: $primary-dark;
    margin-bottom: 0;
}

.notification-date {
    color: $white;
    background: #aaa;
    padding: 0 1rem;
    font-weight: 300;
    border-radius: 4rem;
    &.notification-date--lead {
        background: orangered;
    }
    &.notification-date--review {
        background: royalblue;
    }
}

.notification-icon {
    height: auto;
    max-height: 45px;
}

// notice popup
.notice-popup-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 99;

    display: flex;
    align-items: center;
    justify-content: center;
    &.no-background {
        background: transparent;
    }
}

.notice-popup {
    background: #fff;
    padding: 1rem;
    width: 80%;
    max-width: 500px;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
}

.notice-popup--no-background {
    position: absolute;
    top: 4.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.notice-popup-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
}

.notice-popup-close {
    align-self: flex-end;
    background: transparent;
    border: none;
    font-size: 1.2rem;
    line-height: 1;
}

// empty state screens
.empty-state--container {
    padding: 1rem;
    margin-top: 18%;
}
.empty-state--icon {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 1.5rem;
    > img {
        max-width: 5rem;
        width: 25%;
    }
}

.empty-state--text {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    > p {
        margin-bottom: 0.25rem;
    }
}

@media (min-width: 1024px) {
    .empty-state--container {
        margin-top: 2rem;
    }
}
